import React from 'react';
import { injectIntl, IntlShape } from 'react-intl';

import { OperatorDetail } from 'components/operators/OperatorDetail';

interface IProps {
  pageContext: any;
  intl: IntlShape;
}

const Search = (props: IProps) => {
  const { pageContext: { data = {} } = {} } = props;

  return <OperatorDetail data={data} />;
};

export default injectIntl(Search);
