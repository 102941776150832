import React from 'react';

import Helmet from 'react-helmet';
import { ImageGroup } from 'components/image-group/ImageGroup';
import { Figure } from 'components/figure/Figure';
import { Segment } from 'components/layout/Segment';
import { Link } from 'components/link/Link';
import Mapbox from 'components/mapbox/Mapbox';
import { Row } from 'components/layout/Row';
import { Map, TRegions } from 'components/map/Map';

import { PrismicImage } from '../prismic-image/PrismicImage';
import { MapHeading } from '../slices/MapHeading';

import { SVG } from './SVG'
import s from './OperatorDetail.scss';

interface IProps {
  children?: any;
  data?: any;
  notFound?: boolean;
}

export const OperatorDetail = ({ data = null, notFound }: IProps) => {
  const firstImage = data && data.photos && data.photos.length > 0 ? data.photos[0].photoUri.replace(/^http:/, 'https:') : null;
  const image = firstImage ? firstImage : null;
  const certified = data.vakinn && (data.vakinn.isApprovedTourismService || data.vakinn.isApprovedRestaurant);
  const cleanAndSafe = data.cleanAndSafe
  
  const english = data && data.translations && data.translations.find((i: any) =>
    i.locale && i.locale.toLowerCase() === 'en-us');

  let heading = notFound ? 'Not found' : '';
  let text = '';
  let available = null;
  let location = null;
  let region: TRegions = '';
  let lat = null;
  let lon = null;
  let hasAddress = false;
  let website = null;
  let url = null;
  let tel = null;
  let email = null;
  let address = null;
  let zip = null;

  if (data && data.regions && data.regions.length > 0 && data.regions[0].translations) {
    const englishRegion = data.regions[0].translations.find((i: any) => i.locale === 'en-US');

    if (englishRegion && englishRegion.name) {
      region = englishRegion.name.toLowerCase().replace('capital area', 'reykjavik') as TRegions;
    }
  }

  if (data && data.OpenFrom && data.OpenTo) {
    const from = data.OpenFrom.substr(0, data.OpenFrom.lastIndexOf('/'));
    const to = data.OpenTo.substr(0, data.OpenTo.lastIndexOf('/'));
    available = `${from}–${to}`;
  }

  if (data && data.location && data.location.coordinates) {
    lon = parseFloat(data.location.coordinates[0]);
    lat = parseFloat(data.location.coordinates[1]);
  }

  if (english) {
    heading = english.name;
    text = english.description;
  }

  if (data && data.website) {
    website = data.website;
    url = (!/^(f|ht)tps?:\/\//i.test(website)) ? `http://${website}` : website;
  }

  if (data && data.phoneNr) {
    tel = data.phoneNr;
  }

  if (data && data.email) {
    email = data.email;
  }

  if (data && data.address && data.zipCode) {
    hasAddress = true;
    address = data.address;
    zip = `${data.zipCode}`;
  }

  const hasContact = website || tel || email;

  const MAP_WIDTH = 850;
  const MAP_HEIGHT = 750;

  return (
    <>
      <MapHeading
        heading={heading}
        available={available}
        location={location}
        noOffset
        backLink={<Link to="/search">Back</Link>}
        map={
          <Map
            region={region}
            size="medium"
          />
        }
      />
      <div className={s.operatorDetail}>
        <Helmet
          title={heading}
        />
        <Row>
          <div className={s.operatorDetail__content}>
            <div className={s.operatorDetail__html} dangerouslySetInnerHTML={{ __html: text }} />
          </div>
          {lat && lon && (
            <div className={s.operatorDetail__map}>
              {hasAddress && (
                <div className={s.operatorDetail__address}>
                  <h3 className={s.operatorDetail__addressTitle}>Address</h3>
                  <address>
                    <div className={s.operatorDetail__addressLine}>{heading}</div>
                    <div className={s.operatorDetail__addressLine}>{address}</div>
                    <div className={s.operatorDetail__addressLine}>{zip}</div>
                  </address>
                </div>
              )}
              {hasContact && (
                <div className={s.operatorDetail__contact}>
                  <h3 className={s.operatorDetail__contactTitle}>Contact</h3>
                  {tel &&
                    <div className={s.operatorDetail__contactItem}>
                      <a className={s.operatorDetail__contactItemLink} href={`tel:${tel}`}>{tel}</a>
                    </div>
                  }
                  {email &&
                    <div className={s.operatorDetail__contactItem}>
                      <a className={s.operatorDetail__contactItemLink} href={`mailto:${email}`}>{email}</a>
                    </div>
                  }
                  {url &&
                    <div className={s.operatorDetail__contactItem}>
                      <a className={`${s.operatorDetail__contactItemLink} ${s.operatorDetail__link}`} href={url} target="_blank">{website}</a>
                    </div>
                  }
                  <div className={s.operatorDetail__flex}>
                    {certified && <div className={s.operatorDetail__certified}><SVG height="93" width="70" /></div>}
                    {cleanAndSafe && <div className={s.operatorDetail__certified}><SVG height="90" width="90" svg="CleanAndSafe" /></div>}
                  </div>
                </div>
              )}
              <div className={s.operatorDetail__mapInner}>
                <Mapbox
                  width={MAP_WIDTH}
                  height={MAP_HEIGHT}
                  markers={[{ latitude: lat, longitude: lon }]}
                  zoom={4}
                  center={[lon, lat]}
                />
              </div>
            </div>
          )}
        </Row>
      </div>
      {image && (
        <Segment>
          <ImageGroup
            large={
              <Figure
                image={
                  <PrismicImage
                    image={{ x1: { url: image, dimensions: { width: 100, height: 100 } } }}
                    view="x1"
                  />}
              />
            }
            medium={
              data && data.photos && data.photos.length > 1 ?
                <Figure
                  image={
                    <PrismicImage image={{ x1: { url: data.photos[1].photoUri.replace(/^http:/, 'https:'), dimensions: { width: 100, height: 100 }}}} view="x1" />
                  }
                />
              : undefined
            }
            small={
              data && data.photos && data.photos.length > 2 ?
              <Figure
                image={
                  <PrismicImage image={{ x1: { url: data.photos[2].photoUri.replace(/^http:/, 'https:'), dimensions: { width: 100, height: 100 }}}} view="x1" />
                }
              />
            : undefined
            }
          />
          {data.photos.length > 3 && (
            <ImageGroup
            large={
              data && data.photos && data.photos.length > 3 ?
                <Figure
                  image={
                    <PrismicImage image={{ x1: { url: data.photos[3].photoUri.replace(/^http:/, 'https:'), dimensions: { width: 100, height: 100 }}}} view="x1" />
                  }
                />
              : undefined
            }
            medium={
              data && data.photos && data.photos.length > 4 ?
                <Figure
                  image={
                    <PrismicImage image={{ x1: { url: data.photos[4].photoUri.replace(/^http:/, 'https:'), dimensions: { width: 100, height: 100 }}}} view="x1" />
                  }
                />
              : undefined
            }
            small={
              data && data.photos && data.photos.length > 5 ?
              <Figure
                image={
                  <PrismicImage image={{ x1: { url: data.photos[5].photoUri.replace(/^http:/, 'https:'), dimensions: { width: 100, height: 100 }}}} view="x1" />
                }
              />
            : undefined
            }
          />
          )}
        </Segment>
      )}
    </>
  )
};
